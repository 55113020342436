import React from "react";

import Layout, { LayoutData } from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { PageSection } from "../components/page-section";
import { RaceDetail } from "../components/race-detail";
import { Article } from "../components/article";
import Img from "gatsby-image"
import { FluidImg } from "../components/fluid-img";
import { FluidImage } from "../utils/sharpImages";


const HistoryPage = (props: Props) => {
  const history = props.data.historyPageData
  return (
    <Layout {...props}>
      <SEO
        title="Historie"
      />
      <PageSection>
        <Article className="mb-8" html={history.html} title={history.frontmatter.title} />
        {props.data.racesData.edges.map((race, i) => <div className="mb-16" key={i}>
          <RaceDetail race={race} />
          {race.node.frontmatter.sponsorsImage &&
            <>
              <h4 className="my-3">Sponzoři</h4>
              <FluidImg alt="Sponzoři" fluid={race.node.frontmatter.sponsorsImage.childImageSharp.fluid} />
            </>}
        </div>)}
      </PageSection>

    </Layout>
  );
}

export interface RaceNode {
  node: {
    id: string
    frontmatter: {
      title: string
      formattedDate: string
      youtubeId?: string
      resultLink?: string
      galleryLink?: string
      sponsorsImage?: FluidImage
    }
    html: string
  }
}


interface Props {
  data: {
    historyPageData: {
      frontmatter: {
        title: string
      }
      html: string
    }
    ,
    racesData: {
      edges: RaceNode[]
    }
  } & LayoutData
}

export const pageQuery = graphql`
  query HistoryPageQuery {
    historyPageData: markdownRemark(frontmatter: {contentId: {eq: "history"}}) {
          id
          frontmatter {
            title
          }
          html
        }
    racesData:  allMarkdownRemark(
        filter:{ frontmatter:{ contentId: {eq: "race"}}}, 
        sort:{ fields: [frontmatter___date], order: ASC}) {
      edges {
        node {
          id
          frontmatter {
            contentId
            title
            formattedDate: date(formatString: "l", locale: "cs")
            youtubeId
            resultLink
            galleryLink
            sponsorsImage {
              childImageSharp {
                fluid(maxWidth: 896) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }
            }
          }
          html
        }
      }
    }
    ...LayoutFragment
  }`


export default HistoryPage;
