import React from "react"
import Img from "gatsby-image"
import { FluidObjectWithPresentationWidth } from "../utils/sharpImages"

export const FluidImg = (props: Props) => {
    const style = { ...(props.fluid.presentationWidth ? { maxWidth: props.fluid.presentationWidth, margin: `0 auto` } : {}) }
    console.log(`asdas`, style)
    return <Img {...props} style={style} />
}

interface Props {
    fluid: FluidObjectWithPresentationWidth
    alt: string
}